import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import Layout from "../components/layout/layout"
import favicon from "../images/favicon.ico"

import innovationImg from "../images/marketing/svg/about/innovation.svg"
import secureTransactionImg from "../images/marketing/svg/about/secure-transaction.svg"
import customerServiceImg from "../images/marketing/svg/about/customer-service.svg"
import easeOfUseImg from "../images/marketing/svg/about/ease-of-use.svg"
import ezriel from "../images/marketing/svg/team/ezriel.svg"
import nam from "../images/marketing/svg/team/nam.svg"
import andrea from "../images/marketing/svg/team/andrea.svg"
import jay from "../images/marketing/svg/team/jay.svg"
import rafli from "../images/marketing/svg/team/rafli.svg"
import Kalpesh from "../images/marketing/svg/team/kalpesh.svg"
import Sol from "../images/marketing/svg/team/Sol.svg"
import Ankur from "../images/marketing/svg/team/ankur.svg"
import Sara from "../images/marketing/svg/team/sara.png"
import Anshul from "../images/marketing/svg/team/anshul.svg"
import Patrick from "../images/marketing/svg/team/patrick.svg"
import Victor from "../images/marketing/svg/team/victor.svg"
import Richard from "../images/marketing/svg/team/richard.svg"
import Roselyn from "../images/marketing/svg/team/roselyn.svg"
import Rudi from "../images/marketing/svg/team/rudi.svg"
import Ruthlene from "../images/marketing/svg/team/ruthlene.svg"
import Nathaniel from "../images/marketing/svg/team/nathaniel.svg"
import Jermaine from "../images/marketing/svg/team/jermaine.svg"
import abe from "../images/marketing/svg/team/abe.svg"
import Hedrian from "../images/marketing/svg/team/hedrian.svg"
import Heli from "../images/marketing/svg/team/heli.svg"
import Keishia from "../images/marketing/svg/team/keishia.svg"
import Thinh from "../images/marketing/svg/team/thinh.svg"
import Thanh from "../images/marketing/svg/team/thanh.svg"
import Ayla from "../images/marketing/svg/team/ayla.svg"
import Charlene from "../images/marketing/svg/team/charlene.svg"
import Jaime from "../images/marketing/svg/team/jaime.svg"
import Zach from "../images/marketing/svg/team/zach.svg"
import Alon from "../images/marketing/svg/team/alon.svg"
import Lan from "../images/marketing/svg/team/lan.svg"
import Dory from "../images/marketing/svg/team/dory.svg"
import Carmel from "../images/marketing/svg/team/carmel.svg"

export default () => (
  <div>
    <Helmet>
      <meta charSet="utf-8"></meta>
      <meta
        name="keywords"
        content="pharmacy portal, pharmacy purchasing platform"
      />
      <meta
        name="description"
        content="Learn more about how EzriRx allows pharmacies to save an average of 30% on all drugs. Compare drug prices offered by many suppliers and purchase from the most favorable."
      />
      <title>
        EzriRx.com - Learn About Our Marketplace Platform - 65,000+
        Pharmaceuticals
      </title>
      <link rel="canonical" href="https://www.ezrirx.com/about/" />
      <link rel="shortcut icon" type="image/png" href={`${favicon}`} />
    </Helmet>
    <Layout>
      <div className="body">
        <section className="hero-banner hero-banner--only-text" id="hero-about">
          <div className="contents about">
            <div className="hero-banner__inner">
              <div className="hero-content">
                <h1>
                  Our mission is to revolutionize the process of buying
                  pharmaceuticals.
                </h1>
                <p>
                  We believe the way to drive the pharmaceutical industry
                  forward is by leveraging the latest technology. We work hard
                  to provide innovative tools on our platform so that
                  independent pharmacies have access the best prices on
                  pharmaceuticals.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="introduction-grid-box">
          <div className="contents">
            <div className="title-box text-center">
              <h2>Customer service</h2>
            </div>

            <div className="box-2cols">
              <div className="col col--intro">
                <div className=" col--intro__inner intro-box">
                  <img
                    src={innovationImg}
                    alt="EzriRX is your innovative pharmacy portal partner"
                  ></img>
                  <div className="col--intro__content">
                    <h4>Innovation</h4>
                    <p>
                      EzriRx was born out of innovation, and we continue to
                      bring our platform to the next level with new and
                      innovative features that take us closer to our vision for
                      the future of the pharmaceutical industry.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col--intro">
                <div className=" col--intro__inner intro-box">
                  <img
                    src={secureTransactionImg}
                    alt="Pharmacy Portal Security"
                  ></img>
                  <div className="col--intro__content">
                    <h4>Security</h4>
                    <p>
                      At EzriRx, security means everything to us. Security and
                      privacy are fundamental to the design of our platform, and
                      we continue to follow modern standards and practices to
                      ensure that our users and their payment information is
                      secure using bank-level security protocols
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="box-2cols">
              <div className="col col--intro">
                <div className=" col--intro__inner intro-box">
                  <img
                    src={customerServiceImg}
                    alt="Customer support pharmacy marketplace"
                  ></img>
                  <div className="col--intro__content">
                    <h4>Customer support</h4>
                    <p>
                      We pride ourselves on providing you with above-and-beyond
                      customer service. With our built-in support chat and phone
                      line, you can quickly get to the bottom of any issue by
                      speaking directly with our friendly support staff.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col--intro">
                <div className=" col--intro__inner intro-box">
                  <img
                    src={easeOfUseImg}
                    alt="Easiest to use pharmacy marketplace"
                  ></img>
                  <div className="col--intro__content">
                    <h4>Ease-of-use</h4>
                    <p>
                      EzriRx has been designed to be user-friendly, and does not
                      require you to be “tech-savvy” to understand or use. We
                      work hard to provide a simple and consistent user
                      experience that allows you to use our platform without
                      breaking a sweat.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="marketing-content no-padding-top">
          <div className="contents">
            <div className="title-box">
              <h2>Our team</h2>
            </div>

            <ul className="list-unstyled ez-team">
              <li>
                <img src={ezriel} alt="Ezriel Green"></img>
                <p>
                  <strong>Ezriel Green</strong> Founder &amp; CEO
                </p>
              </li>
              <li>
                <img src={Sol} alt="Sol"></img>
                <p>
                  <strong>Sol</strong> Head of Pharmacy Operations
                </p>
              </li>
              <li>
                <img src={Sara} alt="Sara"></img>
                <p>
                  <strong>Sara</strong> Head of Sales
                </p>
              </li>
              <li>
                <img src={abe} alt="Abe Green"></img>
                <p>
                  <strong>Abe</strong> Strategic Director
                </p>
              </li>
              <li>
                <img src={nam} alt="Nam Nguyen"></img>
                <p>
                  <strong>Nam Nguyen</strong> CTO
                </p>
              </li>
              <li>
                <img src={andrea} alt="Andrea"></img>
                <p>
                  <strong>Andreea</strong> Head of Product
                </p>
              </li>
              <li>
                <img src={jay} alt="Jay"></img>
                <p>
                  <strong>Jay</strong> Head of Engineering
                </p>
              </li>
              <li>
                <img src={Ankur} alt="Ankur"></img>
                <p>
                  <strong>Ankur</strong>Developer
                </p>
              </li>
              <li>
                <img src={Kalpesh} alt="Kalpesh"></img>
                <p>
                  <strong>Kalpesh</strong> Developer
                </p>
              </li>
              <li>
                <img src={Anshul} alt="Anshul"></img>
                <p>
                  <strong>Anshul</strong> Developer
                </p>
              </li>
              <li>
                <img src={Lan} alt="Lan"></img>
                <p>
                  <strong>Lan</strong> Developer
                </p>
              </li>
              <li>
                <img src={Thinh} alt="Thinh"></img>
                <p>
                  <strong>Thinh</strong> Developer
                </p>
              </li>
              <li>
                <img src={Thanh} alt="Thanh"></img>
                <p>
                  <strong>Thanh</strong> Developer
                </p>
              </li>
              <li>
                <img src={rafli} alt="Rafli"></img>
                <p>
                  <strong>Rafli</strong> Designer
                </p>
              </li>
              <li>
                <img src={Alon} alt="Alon"></img>
                <p>
                  <strong>Alon</strong> Design and Marketing
                </p>
              </li>
              <li>
                <img src={Victor} alt="Victor"></img>
                <p>
                  <strong>Victor</strong> Support Engineer
                </p>
              </li>
              <li>
                <img src={Ruthlene} alt="Ruthlene"></img>
                <p>
                  <strong>Ruthlene</strong> Customer Support
                </p>
              </li>
              <li>
                <img src={Ayla} alt="Ayla"></img>
                <p>
                  <strong>Ayla</strong> Customer Support
                </p>
              </li>
              <li>
                <img src={Patrick} alt="Patrick"></img>
                <p>
                  <strong>Patrick</strong> Onboarding Specialist
                </p>
              </li>
              <li>
                <img src={Rudi} alt="Rudi"></img>
                <p>
                  <strong>Rudi</strong> Onboarding Specialist
                </p>
              </li>
              <li>
                <img src={Richard} alt="Richard"></img>
                <p>
                  <strong>Richard</strong> Sales
                </p>
              </li>
              <li>
                <img src={Roselyn} alt="Roselyn"></img>
                <p>
                  <strong>Roselyn</strong> Sales
                </p>
              </li>
              <li>
                <img src={Nathaniel} alt="Nathaniel"></img>
                <p>
                  <strong>Nathaniel</strong> Sales
                </p>
              </li>
              <li>
                <img src={Jermaine} alt="Jermaine"></img>
                <p>
                  <strong>Jermaine</strong> Sales
                </p>
              </li>
              <li>
                <img src={Hedrian} alt="Hedrian"></img>
                <p>
                  <strong>Hedrian</strong> Sales
                </p>
              </li>
              <li>
                <img src={Heli} alt="Heli"></img>
                <p>
                  <strong>Heli</strong> Sales
                </p>
              </li>
              <li>
                <img src={Keishia} alt="Keishia"></img>
                <p>
                  <strong>Keishia</strong> Sales
                </p>
              </li>
              <li>
                <img src={Charlene} alt="Charlene"></img>
                <p>
                  <strong>Charlene</strong> Sales
                </p>
              </li>
              <li>
                <img src={Jaime} alt="Jamie"></img>
                <p>
                  <strong>Jamie</strong> Sales
                </p>
              </li>
              <li>
                <img src={Zach} alt="Zach"></img>
                <p>
                  <strong>Zach</strong> Sales
                </p>
              </li>
            </ul>

            <div className="intro-box careers-box">
              <div className="careers-box__inner">
                <div className="careers-box__info">
                  <p>
                    <strong>Join our team.</strong> Help us build the best
                    pharmaceutical e-commerce platform in the world.
                  </p>
                </div>

                <Link
                  to="/careers/"
                  className="button with-border with-border--blue"
                >
                  Careers at EzriRx
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  </div>
)
